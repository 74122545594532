import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {Box, Grid, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {sectionSpacing, TEXT_PADDING} from "../theme";


export default () => {
  return (
    <>
      <Toolbar />
      <Container
        maxWidth={'md'}
      >
        <Box
          marginY={sectionSpacing}
          textAlign={'center'}
        >
          <Typography
            variant={'subtitle1'}
            fontWeight={'bold'}
            textTransform={'uppercase'}
          >
            Extra
          </Typography>
          <Typography variant={'h3'}>
            De <Box component={'span'} color={'primary.main'}>toekomst</Box> is nu
          </Typography>
          <Typography paragraph>
            Bij het ontwerp van woonconcept de Wachters worden er op het gebied van duurzaamheid geen concessies gedaan. Een overzicht van prestaties en innovaties.
          </Typography>
        </Box>
      </Container>
      <Container
        maxWidth={'lg'}
      >
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Box
                paddingRight={{md: TEXT_PADDING}}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Collectieve verwarming
                </Typography>
                <Typography variant={'body2'} paragraph>
                  De komende jaren zal Ennatuurlijk duurzaam warmte leveren aan de 3.000 woningen in Zeewolde. De leveringen van deze groene warmte is een uniek energiezuinig project. De groene warmte wordt geproduceerd met een warmtekrachtkoppeling op biogas van melkveehouderij Van Beek uit Zeewolde. Dit wordt verkregen uit mest van koeien, vermengd met restanten van akkerbouw, de voedingsindustrie en grasmaaisel uit natuurgebieden. Dit principe is niet alleen goed voor het milieu, maar levert uiteindelijk ook een kostenbesparing op voor de inwoner.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/spa-bathroom-in-luxurious-modern-villa-with-huge-natural-rock-wall-picture-id1253547527@2x.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
              order={{md: 2}}
            >
              <Box
                paddingLeft={{md: TEXT_PADDING}}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Groen in en om het huis
                </Typography>
                <Typography variant={'body2'} paragraph>
                  De tuinen van alle woningen bij project  De Wachters liggen  aan direct vaarwater. De Brugwachter heeft eigen aanlegsteiger en Sluiswachter heeft een gemeenschappelijk aanlegsteiger. Uiteraard bent u helemaal vrij om uw tuin naar eigen wens in te vullen. De woningen krijgen elk een eigen waterton, zodat het regenwater kan worden opgevangen. Daarnaast zijn de woningen en appartementen gasloos hebben een hoge isolatiewaarde en beschikken over zonnepanelen. Uiterst duurzaam wonen!
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/7c8c4ec0-7f3a-49bc-bb5e-7c1e2f4e6094.jpg&h=630&w=1000&mode=crop&v=1512056498@2x.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Box
                paddingRight={{md: TEXT_PADDING}}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  De keuken die u altijd al wilde
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Heel strak, modern of juist klassiek? Een kookeiland of heel minimalistisch? Er is bewust voor gekozen om in de basis geen keuken mee te nemen. Wel zijn er standaard aansluitpunten aangesloten die naar wens zijn te verplaatsen en uit te breiden.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/GettyImages-1217234710@2x.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              md={6}
              order={{md: 2}}
              display={'flex'}
            >
              <Box
                paddingLeft={{md: TEXT_PADDING}}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Heerlijk, uw badkamer
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Heerlijk even de boel de boel laten in uw badkamer. Hoe gaat ‘ie eruit zien? Het toilet op de begane grond en de badkamer boven worden compleet opgeleverd met tegelwerk en sanitair. Standaard is de badkamer voorzien van een vrijhangend toilet, wastafel, radiator en doucheopstelling. Houdt u van uitgebreid badderen? Upgrade ‘m dan met een luxe ligbad voor extra ontspanningsmomenten.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/GettyImages-1198988779@2x.jpg'}
                alt={'vogel'}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
